<template>
    <div>
        <div style="position: relative;background-color: #d9e4f5;width: auto;height: 710px">
            <div style="position: absolute;background-color: #f3f8fe;width: 20%;height: 85%;margin-left:40%;margin-top: 5%;border-radius: 30px;
                 box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1)" v-if="loading">
                <div style="background-image: url('http://localhost:8081/api/files/logo');width: 60%;height: 30%;margin-left: 10%;"></div>
                <div style="position: absolute;background-color: #FFFFFF;width: 80%;height: 12%;margin-left:10%;margin-top:50px;border-radius: 15px;
                 box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);">
                    <div style="color: #a0a5b2;margin-top: 10px;margin-left: 20px;font-weight: bolder">账户</div>
                    <i class="el-icon-user" style="color: #a0a5b2;margin-top: 16px;margin-left: 25px"></i>
                    <!--                    <el-input prefix-icon="el-icon-user" v-model="input" placeholder="请输入内容" style="width: 60%;height: 10px"></el-input>-->
                    <input type="text" v-model="admin.name" style="margin-left: 15px;border: none;outline: none;width: 50%">
                </div>
                <div style="position: absolute;background-color: #FFFFFF;width: 80%;height: 12%;margin-left:10%;margin-top:150px;border-radius: 15px;
                 box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);">
                    <div style="color: #a0a5b2;margin-top: 10px;margin-left: 20px;font-weight: bolder">密码</div>
                    <i class="el-icon-lock" style="color: #a0a5b2;margin-top: 16px;margin-left: 25px"></i>
                    <input type="password" v-model="admin.password" style="margin-left: 15px;border: none;outline: none;width: 50%" >
                </div>
                <el-button style="width: 80%; margin-left: 10%;margin-top: 250px;background-color: #414886;
                color: #FFFFFF;border-radius: 30px" @click="register()">注册</el-button>
                <div style="font-size: smaller;color:#888787;margin-top: 20px;margin-left: 30px;cursor: pointer;float: left" @click="navLogin()">登录</div>
                <div style="font-size: smaller;color:#888787;margin-top: 20px;cursor: pointer;position: absolute;right: 0" @click="navLogin()">忘记密码？</div>
            </div>
            <div v-if="onloading">
                <div style="position: absolute;top: 49%;margin-left: 48%;color: red">loading...</div>
                <div class="isloading"></div>
            </div>
        </div>
    </div>

</template>
<script>


import request from "@/utils/request";

export default {
  name:"Login",
  data() {
    return {
      user:localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) :{},
      admin:{},
        loading:true,
        onloading:false
      // new-password:null,
    }
  },
  //页面加载的时候，做一些事情，在created里面
  created() {
  },
  //定义一些页面上控件触发的事件调用的方法
  methods:{
    register(){
        this.loading=false;
        this.onloading=true;
        setTimeout(()=>{
            this.onloading=false;
            request.post("/admin/register", this.admin).then(res => {
                if (res.code === '0') {
                    this.$message({
                        message: '注册成功',
                        type: 'success'
                    });
                    this.$router.push("/login");
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    });
                }
            })
        },1500);


    },
    navLogin(){
      this.$router.push("/login")
    }
  }
}
</script>
<style>
.isloading{
    position: absolute;
    width:150px;
    height:150px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #9370db;
    margin-left: 45%;
    top:40%;
    animation: rotate 2s linear infinite;
}
.isloading::before{
    content: "";
    position: absolute;
    top:5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #ba55d3;
    animation: rotate 3s linear infinite;
}
.isloading::after{
    content: "";
    position: absolute;
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #f0f;
    animation: rotate 1.5s linear infinite;
}
@keyframes rotate {
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(360deg);
    }
}
</style>