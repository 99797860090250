<template>
    <div  style="background-color: #FFFFFF;height: auto;box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    border-radius: 20px;position: absolute;top: 90px;width: 83%;margin-left: 10px">
        <el-page-header @back="goBack" style="margin-top: 10px;margin-left: 20px"></el-page-header>
        <div style="top: 20px;margin-left: 70px;position: relative">
            <div style="float: left;margin-top: 10px">脚本名：</div>
            <el-input v-model="params.script_name" style="width:150px;float: left" clearable @change="findGrope"></el-input>
            <div style="float: left;margin-top: 10px;margin-left: 10px">运营方：</div>
            <el-input v-model="params.script_operator" style="width:150px;margin-left: 5px;float: left" clearable @change="findGrope"></el-input>
        </div>
        <div style="margin-left: 30px;position: relative;margin-top: 80px;width: 1470px">

            <el-table
                    :data="tableData" border  ref="table" v-loading="loading" max-height="600px">
                <el-table-column label="头像" width="75px">
                    <template  v-slot="scope">
                        <el-image
                            style="width: 50px; height: 50px;border-radius: 50%"
                            :src="net_url+scope.row.script_img"
                            :preview-src-list="[net_url+scope.row.script_img]"
                            :fit="fit">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="script_name" label="脚本名" width="200"></el-table-column>
                <el-table-column prop="script_brief_introduction" label="脚本简介" width="200"></el-table-column>
                <el-table-column prop="script_operator" label="运营方" width="200"></el-table-column>
                <el-table-column prop="script_user_number" label="月度用户量" width="200"></el-table-column>
                <el-table-column prop="script_fens" label="粉丝" width="200"></el-table-column>
                <el-table-column prop="script_status" label="脚本状态" width="200"></el-table-column>
                <el-table-column prop="script_initial_robot" label="初始机器人" width="200"></el-table-column>
                <el-table-column prop="script_version" label="脚本版本" width="200"></el-table-column>
                <el-table-column label="操作" fixed="right" width="150px">
                    <template slot-scope="scope">
                        <el-tooltip content="编辑">
                            <el-button @click="edit(scope.row)" class="el-icon-edit" style="font-size: large;border: none"></el-button>
                        </el-tooltip>
                        <el-tooltip content="删除">
                                <el-button  style="border: none;font-size: large" class="el-icon-delete" @click="del(scope.row.id)"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div style ="margin-top: 50px;float:left;margin-bottom: 20px;margin-left: 10px">
            <span class="demonstration"></span>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="params.pageNum"
                :page-sizes="[5,10, 15, 20]"
                :page-size="params.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </div>
        <el-dialog title="请填写信息" :visible.sync="dialogFormVisible" width="30%">
            <el-form :model="form">
                <el-form-item label="脚本名" label-width="20%">
                    <el-input v-model="form.script_name" autocomplete="off" style="width:90%"></el-input>
                </el-form-item>
                <el-form-item label="头像" label-width="20%">
                    <el-upload
                        class="avatar-uploader"
                        :action="this.load_url"
                        :on-success="handleAvatarSuccess"
                        :show-file-list="false"
                        :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl" :src="imageUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="简介" label-width="20%">
                    <el-input v-model="form.script_brief_introduction" autocomplete="off" style="width:90%"></el-input>
                </el-form-item>
                <el-form-item label="脚本运营方" label-width="20%">
                    <el-input v-model="form.script_operator" autocomplete="off" style="width:90%"></el-input>
                </el-form-item>
                <el-form-item label="脚本版本" label-width="20%">
                    <el-input v-model="form.script_version" autocomplete="off" style="width:90%"></el-input>
                </el-form-item>
                <el-form-item label="脚本初始机器人" label-width="20%">
                    <el-input v-model="form.script_initial_robot" autocomplete="off" style="width:90%"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>

import request from "@/utils/request";
import LoginView from "@/views/LoginView.vue";


export default {

    data() {

        return {
            params:{
                pageNum:1,
                pageSize:5,
                script_operator:'',
                script_name:''
            },
            loading: true,
            imageUrl: '',
            net_url:LoginView.data().net_url,
            load_url:LoginView.data().load_url,
            shan_img:[],
            fit:["contain"],
            notice:{},
            ceshi:[],
            tableData: [],
            total:0,
            dialogFormVisible:false,
            dialogImgVisible:false,
            form:{},
            typeObjs:[],
            detail_img:{},
            del_img:[],
            multipleSelection:[],
            multipleSelections:[],
            user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
        }
    },
    //页面加载的时候，做一些事情，在created里面
    created() {
        this.findBySearch();
        // this.findTypes();
    },
    //定义一些页面上控件触发的事件调用的方法
    methods:{
        findBySearch(){
            let attention=this.$route.query.attention;
            let script=this.$route.query.script;
            request.get("/script/search").then(res =>{
                if(res.code ==='0'){
                    const list=[];
                    let m=0;
                    for(let i=0;i<res.data.length;i++){
                        if(res.data[i].id==attention||res.data[i].id==script){
                            list[m]=res.data[i];
                            m++;
                        }
                    }
                    this.tableData =list;
                    // this.ceshi=list;
                    this.total= list.length;
                }else{
                    this.$message.error(res.msg)

                }
            })
            setTimeout(()=>{this.loading=false;},1000)
        },
        findGrope(){
            this.loading=true;
            if(this.params.script_operator+this.params.script_name===''){
                this.findBySearch()
            }else {
                this.params.pageSize=5;
                this.params.pageNum=1;
                request.get("/script/grope",{
                    params:this.params
                }).then(res=>{
                    if(res.code ===0){
                        this.tableData=res.data
                        this.total=res.total;
                        this.ceshi=res
                    }else{
                        this.$message.error(res.msg)
                    }
                })
                setTimeout(()=>{this.loading=false;},1000)
            }

        },
        table_refresh(){
            this.loading=true;
            this.findBySearch();
        },
        submit(){
            /*request.post("/admin",this.form).then(res=>{
                if(res.code ==='0'){
                    this.dialogFormVisible=false;
/!*                    localStorage.removeItem("user");
                    localStorage.setItem("user",JSON.stringify(this.form));*!/
                    this.$router.go(0);
                }else{
                    this.$message.error(res.msg)

                }
            })*/
            request.post("/script",this.form).then(res=>{
                if(res.code ==='0'){
                    this.$message({
                        message: '操作成功',
                        type: 'success'
                    });
                    this.dialogFormVisible=false;
                    this.findBySearch();
                }else{
                    this.$message.error(res.msg)

                }
            })
            this.imageUrl='';
            this.form={};
        },
        edit(obj){
            this.imageUrl=this.net_url+obj.script_img
            this.form=obj;
            this.dialogFormVisible=true;
        },
        successUpload(res){
            this.form.img=res.data;
        },
        add(){
            this.imageUrl=''
            this.form={};
            this.dialogFormVisible=true;
        },
        handleSizeChange(pageSize){
            this.params.pageSize =pageSize;
            if(this.params.script_operator+this.params.script_name===''){
                this.findBySearch()
            }else {
                this.findGrope()
            }
        },
        handleCurrentChange(pageNum){
            this.params.pageNum =pageNum;
            if(this.params.script_operator+this.params.script_name===''){
                this.findBySearch()
            }else {
                this.findGrope()
            }
        },
        del(id){
            request.delete("/user/"+id).then(res=>{
                if(res.code ==='0'){
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.findBySearch();
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        handleSelectionChange(val){
            this.multipleSelection = val;
        },
        getRowKeys(row){
            return row.id;
        },
        handleAvatarSuccess(res) {
            this.form.script_img=res.img_address
            this.imageUrl=this.net_url+this.form.script_img
        },
        beforeAvatarUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isLt2M;
        },
        goBack(){
            this.$router.push("/user")
        }
    }
}
</script>
<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>