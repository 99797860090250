<template >
    <div >
        <div style="background: linear-gradient(#141e30, #243b55);height: 950px">
            <div style="position: absolute;height: 500px;width: 550px;top: 25%;left: 35%;background-color: rgba(0, 0, 0, 0.2);box-shadow: 0 15px 25px rgba(0, 0, 0, 0.4);">
<!--                <div style="position: relative;top: 10%;left: 35%;font-size: xx-large;color: #FFFFFF;">AI自动化脚本</div>-->
                <div style="display: flex;justify-content: center;font-size: xx-large;color: #FFFFFF;margin-top: 10%">AI自动化脚本后台管理系统</div>
                <div style="display: flex;justify-content: center;font-size: small;color: #c0c0c0;margin-top: 1%">AI AUTOMATION SCRIPTS SYSTEM</div>
                <div style="display: flex;justify-content: center;width: 300px;height:auto;margin-top: 4%">
                    <div class="el-icon-user-solid" style="color: #05e9f4;font-size: x-large;margin-left: 10px;margin-top: 10px"></div>
                    <div style="color: #05e9f4;font-size: large;margin-left: 10px;margin-top: 10px">用户名</div>
                </div>
                <div >
                    <input type="text" v-model="admin.account_number" autocomplete="off" style="width: 60%;margin-left: 20%;
                    background-color:transparent;outline: none;border: none;color: #FFFFFF;height:30px
                        ;border-bottom: 1px solid #989898">
                </div>
                <div style="display: flex;justify-content: center;width: 300px;height:auto;margin-top: 4%">
                    <div class="el-icon-lock" style="color: #05e9f4;font-size: x-large;margin-top: 10px"></div>
                    <div style="color: #05e9f4;font-size: large;margin-left: 10px;margin-top: 10px">密码</div>
                </div>
                <div>
                    <input type="password" v-model="admin.password" autocomplete="off" style="width: 60%;margin-left: 20%;
                    background-color:transparent;outline: none;border: none;color: #FFFFFF;height:30px
                        ;border-bottom: 1px solid #989898">
                </div>
                <div style="margin-left: 20%">
                    <img :src="captchaUrl" id="referflush" @click="change_img" width="auto" height="45px" style="margin-top: 20px;margin-left: 10px">
                    <input type="text" v-model="admin.verCode" autocomplete="off" style="width: 28%;
                    background-color:transparent;outline: none;border: none;color: #FFFFFF;margin-left: 10px;border-bottom: 1px solid #989898">
                </div>
                <button style="position: relative;background-color:transparent;color: #05e9f4;border: none;border-bottom: 1px solid #05e9f4;
              font-size: large;margin-left: 35%;margin-top: 40px;cursor: pointer;outline: none;" @click="login()" v-loading.fullscreen.lock="loading">登录</button>
                <button style="position: relative;background-color:transparent;color: #05e9f4;border: none;border-bottom: 1px solid #05e9f4;
              font-size: large;margin-left: 10%;margin-top: 40px;cursor: pointer;outline: none;" @click="dialogFormVisible=true" v-loading.fullscreen.lock="loading">注册</button>


<!--                <div style="position: absolute;top: 55%;left: 50%;width: 300px;height: 50px;background-color: #eff6f9;border-radius: 10px">
                    <div class="el-icon-lock" style="color: #bac2cb;font-size: x-large;margin-left: 10px;margin-top: 10px"></div>
                    <input type="password" v-model="admin.password" autocomplete="off" style="position: absolute;margin-left: 15px;margin-top: 15px;border: none;outline: none;width: 80%;
                    background-color:#eff6f9" placeholder="请输入密码">
                </div>
                <div style="position: absolute;top: 63%;left: 50%;width: 300px;height: 50px;background-color: #eff6f9;border-radius: 10px">
                    <img :src="captchaUrl" id="referflush" @click="change_img" width="100px" height="33px" style="margin-top: 10px;margin-left: 20px">
                    <input v-model="admin.verCode" style="position: absolute;border:none;outline: none;margin-top: 15px;width: 45%;
                    margin-left: 15px;font-size: large" placeholder="请输入验证码"  @keyup.enter="login">
                </div>
                <el-button style="position: absolute;top: 71%;left: 50%;border: none;background-color: #02aaf4;color: #FFFFFF;
                border-radius: 10px;width: 200px" @click="login()" v-loading.fullscreen.lock="loading">登录</el-button>
                <el-link style="position: absolute;top: 72%;left: 64%" target="_blank" @click="dialogFormVisible=true">注册</el-link>-->
            </div>
        </div>
        <div>
            <el-dialog title="请填写信息" :visible.sync="dialogFormVisible" width="30%">
                <el-form :model="form">
                    <el-form-item label="昵称" label-width="20%">
                        <el-input v-model="form.name" autocomplete="off" style="width:90%"></el-input>
                    </el-form-item>
                    <el-form-item label="头像" label-width="20%">
                        <el-upload
                            class="avatar-uploader"
                            name="img"
                            :action="this.load_url"
                            :on-success="handleAvatarSuccess"
                            :show-file-list="false"
                            :before-upload="beforeAvatarUpload">
                            <img v-if="imageUrl" :src="imageUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="账号" label-width="20%">
                        <el-input v-model="form.account_number" autocomplete="off" style="width:90%"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" label-width="20%">
                        <el-input v-model="form.password" autocomplete="off" style="width:90%" type="password"></el-input>
                    </el-form-item>
                    <el-form-item label="电话" label-width="20%">
                        <el-input v-model="form.phone" autocomplete="off" style="width:90%"></el-input>
                    </el-form-item>
                    <el-form-item label="IP地址" label-width="20%">
                        <el-input v-model="form.ip_address" autocomplete="off" style="width:90%"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submit()">确 定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>


<script>
import request from "@/utils/request";
document.body.style.overflow = 'hidden'
export default {
  name:"Login",
  data() {
    return {
        user:localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) :{},
        admin:{},
        loading:false,
        onloading:false,
        key:"",
        imageUrl: '',
        captchaUrl:"",
        net_url:'https://api.tomovideo.com/',
        load_url:"https://api.tomovideo.com/files/uploadImg",
        dialogFormVisible:false,
        form:{
            img:'',
            name:'',
            password:'',
            phone:'',
            ip_address:'',
            account_number:'',
        },
    }

  },
  //页面加载的时候，做一些事情，在created里面
  created() {
  },
    mounted() {
      this.change_img();
    },
    //定义一些页面上控件触发的事件调用的方法
  methods:{
    login(){
        this.loading=true;
        setTimeout(()=>{
            this.onloading=false;
            request.post("/admin/login",this.admin).then(res =>{
                if(res.code ==='0'){
                    this.$message({
                        message: '登录成功',
                        type: 'success'
                    });
                    localStorage.setItem("user",JSON.stringify(res.data));
                    // window.location.href="http://localhost:63342/project1/index.html?type="+this.user.name;
                    if(res.data.role==="boss"){
                        this.$router.push({
                            path:"/admin"
                        })
                    }else if(res.data.role==="admin") {
                        this.$router.push({
                            path:"/user"
                        })
                    }else{
                        this.$message.error("未授权登录，请联系系统管理者")
                    }
                }else{
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    });
                    setTimeout(()=>{
                        this.$router.go(0)
                    },2000)

                }
            })

        },1500);

    },
      submit(){
        if(this.form.account_number*this.form.ip_address*this.form.phone*this.form.password*this.form.name*this.form.img==''){
            this.$message.error("请填写完整信息")
        }else{
            this.form.role='user'
            request.post("/admin/register",this.form).then(res=>{
                if(res.code ==='0'){
                    this.$message({
                        message: '注册成功',
                        type: 'success'
                    });
                    this.dialogFormVisible=false;
                }else{
                    this.$message.error(res.msg)
                }
            })
            this.form={};
        }

      },
      change_img(){
          this.captchaUrl=this.net_url+'/captcha'
      },
    navRegister(){
      this.$router.push("/register")
    },
      handleAvatarSuccess(res) {
          this.form.img=res.img_address
          this.imageUrl=this.net_url+this.form.img
      },
      beforeAvatarUpload(file) {
          const isLt2M = file.size / 1024 / 1024 < 2;
          if (!isLt2M) {
              this.$message.error('上传头像图片大小不能超过 2MB!');
          }
          return isLt2M;
      }
  }
}
</script>