<template>
    <div  style="background-color: #FFFFFF;height: auto;box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    border-radius: 20px;position: absolute;top: 90px;width: 83%;margin-left: 10px">
        <el-page-header @back="goBack" style="margin-top: 10px;margin-left: 20px"></el-page-header>
        <div style="top: 20px;margin-left: 70px;position: relative">
            <div style="float: left;margin-top: 10px">编号：</div>
            <el-input v-model="params.record_id" style="width:150px;float: left" clearable @change="findGrope"></el-input>
            <div style="float: left;margin-top: 10px;margin-left: 10px">需求：</div>
            <el-input v-model="params.need" style="width:150px;margin-left: 5px;float: left" clearable @change="findGrope"></el-input>
            <div style="float: left;margin-top: 10px;margin-left: 10px">时间：</div>
            <el-input v-model="params.chat_time" style="width:150px;margin-left: 5px;float: left" clearable @change="findGrope"></el-input>
        </div>
        <div style="margin-left: 30px;position: relative;margin-top: 80px">

<!--            <el-table
                    :data="tableData" border style="width: 98%;" ref="table" v-loading="loading" >
                <el-table-column prop="record_id" label="编号" width="200"></el-table-column>
                <el-table-column prop="need" label="需求" width="200"></el-table-column>
                <el-table-column prop="need" label="时间" width="200"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-tooltip content="编辑">
                            <el-button @click="edit(scope.row)" class="el-icon-edit" style="font-size: large;border: none"></el-button>
                        </el-tooltip>
                        <el-tooltip content="删除">
                                <el-button  style="border: none;font-size: large" class="el-icon-delete" @click="del(scope.row.id)"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>-->
            <el-table
                :data="tableData"
                 v-loading="loading" max-height="600px">
                <el-table-column type="expand" row-key="need_id">
                    <template slot-scope="props">
                        <el-form label-position="left" inline class="demo-table-expand" >
                            <el-form-item >
                                <div v-for="children in tableData_children" v-if="children.need_id==props.row.id" style="margin-left: 20px">
                                    <span>{{children.chat_time}}</span>
                                    <span style="margin-left: 20px">{{children.chat_to}}：</span>
                                    <span>{{children.chat_record}}</span>
                                </div>
                            </el-form-item>
                        </el-form>
                    </template>
                </el-table-column>
                <el-table-column
                    label="编号"
                    width="200px"
                    prop="record_id">
                </el-table-column>
                <el-table-column label="头像" width="75px;">
                    <template  v-slot="scope">
                        <el-image
                            style="width: 50px; height: 50px;border-radius: 50%"
                            :src="net_url+scope.row.chat_img"
                            :preview-src-list="[net_url+scope.row.chat_img]"
                            :fit="fit">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column
                    label="需求"
                    width="200px"
                    prop="need">
                </el-table-column>
                <el-table-column
                    label="时间"
                    prop="chat_time">
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-tooltip content="删除">
                            <el-button  style="border: none;font-size: large" class="el-icon-delete" @click="del(scope.row.id)"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
        </div>
<!--        <div style ="margin-top: 50px;float:left;margin-left: 20px;margin-bottom: 20px">
            <span class="demonstration"></span>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="params.pageNum"
                :page-sizes="[5,10, 15, 20]"
                :page-size="params.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </div>-->


        </div>

</template>
<script>

import request from "@/utils/request";
import AdminView from "@/views/AdminView.vue";
import LoginView from "@/views/LoginView.vue";


export default {

    data() {

        return {
            params:{
                pageNum:1,
                pageSize:5,
                chat_time:'',
                need:'',
                record_id:''
            },
            loading: true,
            imageUrl: '',
            net_url:LoginView.data().net_url,
            load_url:LoginView.data().load_url,
            shan_img:[],
            fit:["contain"],
            notice:{},
            ceshi:[],
            total:0,
            dialogFormVisible:false,
            dialogImgVisible:false,
            form:{},
            typeObjs:[],
            detail_img:{},
            del_img:[],
            multipleSelection:[],
            multipleSelections:[],
            user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
            tableData: [],
            tableData_children:[],
            user_id:'',
            currentTime: new Date().toLocaleDateString()
        }
    },
    //页面加载的时候，做一些事情，在created里面
    created() {
        this.findBySearch();
        // this.findTypes();
    },
    //定义一些页面上控件触发的事件调用的方法
    methods:{
        findBySearch(){
            this.user_id=this.$route.query.id;
            request.get("/chat_type").then(res =>{
                if(res.code ==='0'){
                    const list=[];
                    let m=0;
                    for(let i=0;i<res.data.length;i++){
                        if(this.user_id==res.data[i].chat_user_id){
                            list[m]=res.data[i];
                            request.get("/chat_type/chat").then(res=>{
                                if(res.code==='0'){
                                    this.tableData_children=res.data
                                }else{
                                    this.$message.error(res.msg)
                                }
                            });
                            m++;
                        }

                    }
                    this.tableData =list;
                    this.total=list.length;
                }else{
                    this.$message.error(res.msg)
                }
            })
            setTimeout(()=>{this.loading=false;},1000)
            this.ceshi=this.currentTime;
            // request.post("")
        },
        findGrope(){
            this.loading=true;
            if(this.params.record_id+this.params.chat_time+this.params.need===''){
                this.findBySearch()
            }else {
                this.params.pageSize=5;
                this.params.pageNum=1;
                request.get("/chat_type/grope",{
                    params:this.params
                }).then(res=>{
                    if(res.code ===0){
                        this.tableData=res.data
                        this.total=res.total;
                        this.ceshi=res
                    }else{
                        this.$message.error(res.msg)
                    }
                })
                setTimeout(()=>{this.loading=false;},1000)
            }

        },
        table_refresh(){
            this.loading=true;
            this.findBySearch();
        },
/*        submit(){
            request.post("/chat_type",this.form)
            request.post("/chat",this.form).then(res=>{
                if(res.code ==='0'){
                    this.$message({
                        message: '操作成功',
                        type: 'success'
                    });
                    this.dialogFormVisible=false;
                    this.findBySearch();
                }else{
                    this.$message.error(res.msg)

                }
            })
            this.form={};
        },*/
/*        edit(obj){
            this.imageUrl='http://localhost:8081/api/files/'+obj.chat_img
            this.form=obj;
            this.dialogFormVisible=true;
        },*/
/*        successUpload(res){
            this.form.img=res.data;
        },
        add(){
            this.imageUrl=''
            this.form={};
            this.dialogFormVisible=true;
        },*/
        handleSizeChange(pageSize){
            this.params.pageSize =pageSize;
            if(this.params.record_id+this.params.chat_time+this.params.need===''){
                this.findBySearch()
            }else {
                this.findGrope()
            }
        },
        handleCurrentChange(pageNum){
            this.params.pageNum =pageNum;
            if(this.params.record_id+this.params.chat_time+this.params.need===''){
                this.findBySearch()
            }else {
                this.findGrope()
            }
        },
        del(id){
            request.get("/chat_type/chat").then(res=>{
                if(res.code==='0'){
                    for(let i=0;i<res.data.length;i++){
                        if(res.data[i].need_id==id){
                            request.delete("/chat_type/chat/"+res.data[i].id)
                            this.ceshi=id
                        }
                    }
                }else{
                    this.$message.error(res.msg)
                }
            })
            request.delete("/chat_type/"+id).then(res=>{
                if(res.code ==='0'){
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.findBySearch();
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        handleSelectionChange(val){
            this.multipleSelection = val;
        },
        getRowKeys(row){
            return row.id;
        },
        handleAvatarSuccess(res) {
            this.form.chat_img = res.img_address;
            this.imageUrl=this.net_url+this.form.chat_img;
        },
        beforeAvatarUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isLt2M;
        },
        goBack(){
            this.$router.push("/user")
        }
    }
}
</script>
<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
.demo-table-expand {
    font-size: 0;
}
.demo-table-expand label {
    width: 90px;
    color: #99a9bf;
}
.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
}
</style>