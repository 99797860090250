import Vue from 'vue'
import VueRouter from 'vue-router'
import LayoutView from "@/views/LayoutView.vue";
import LoginView from "@/views/LoginView.vue";
import RegisterView from "@/views/RegisterView.vue";
import UserView from "@/views/UserView.vue";
import AdminView from "@/views/AdminView.vue";
import ChatrecordView from "@/views/ChatrecordView.vue";
import ScriptView from "@/views/ScriptView.vue";
import User_scriptView from "@/views/User_scriptView.vue";
import UpdateView from "@/views/UpdateView.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterView
  },
  {
    path: '/',
    name: 'Layout',
    component: LayoutView,
    children:[
      {
        path: 'admin',
        name: 'admin',
        component:AdminView
      },
      {
        path: 'user',
        name: 'user',
        component: UserView
      },
      {
        path: 'chatrecord',
        name: 'chatrecord',
        component: ChatrecordView
      },
      {
        path: 'script',
        name: 'script',
        component: ScriptView
      },
      {
        path: 'user_script',
        name: 'user_script',
        component: User_scriptView
      },
      {
        path: 'update',
        name: 'update',
        component: UpdateView
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// 路由守卫
router.beforeEach((to ,from, next) => {
  const user = localStorage.getItem("user");
  if (!user && to.path !== '/login'&& to.path !=='/register') {
    return next("/login");
  }else if(to.path==='/'&&user.role==="boss"){
    return next("/admin")
  }else if(to.path==='/'&&user.role==="admin"){
    return next("/user")
  }
  next();
})

export default router
