<<template>
    <div  >
        <el-container>
            <el-aside style="min-height: 100vh; background-color: #02172a; width: 200px">
<!--                <div style="width: 50px;height: 50px;border-radius: 50%;background-color: #bd43fa;margin-left: 10px;margin-top: 20px;float: left">
                    <div STYLE="margin-top: 3px;margin-left: 10px;position: absolute;font-size: xx-large;color: #FFFFFF">AI</div>
                </div>-->
<!--                <i style="position: absolute;margin-left: 30px;margin-top: 25px;font-size: xx-large;font-weight: bold;color: #225ce6">ATMSP</i>
                <div style="color: #bd43fa;font-weight: bold;font-size: large;position: absolute;top: 120px;left: 20px">Menu</div>
                <div style="position: relative;cursor: pointer"  @click="admin()">
                    <div style="width: 35px;height: 35px;border-radius: 50%;background-color: #bd43fa;margin-top: 160px;margin-left: 15px;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)"></div>
                    <div class="el-icon-s-custom" style="position: absolute;top: 5px;left: 20px;font-size: x-large;color: #FFFFFF"></div>
                    <el-button style="position: absolute;left: 40px;top: -3px;font-size: large;background-color: transparent;border: none">管理员信息</el-button>
                </div>
                <div style="position: relative;cursor: pointer;margin-top: 20px" @click="editing_user()">
                    <div style="width: 35px;height: 35px;border-radius: 50%;background-color: #bd43fa;margin-left: 15px;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)"></div>
                    <div class="el-icon-user-solid" style="position: absolute;top: 5px;left: 20px;font-size: x-large;color: #FFFFFF"></div>
                    <el-button style="position: absolute;left: 40px;top: -3px;font-size: large;background-color: transparent;border: none">用户信息</el-button>
                </div>
                <div style="position: relative;cursor: pointer;margin-top: 20px" @click="source_material()">
                    <div style="width: 35px;height: 35px;border-radius: 50%;background-color: #bd43fa;margin-left: 15px;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)"></div>
                    <div class="el-icon-user-solid" style="position: absolute;top: 5px;left: 20px;font-size: x-large;color: #FFFFFF"></div>
                    <el-button style="position: absolute;left: 40px;top: -3px;font-size: large;background-color: transparent;border: none">素材管理</el-button>
                </div>-->
                <i style="position: absolute;margin-left: 30px;margin-top: 25px;font-size: xx-large;font-weight: bold;color: #225ce6">ATMSP</i>
                <el-menu
                    :default-active="$route.path" router
                    class="el-menu-vertical-demo"
                    background-color="#04192c"
                    text-color="#FFFFFF"
                    active-text-color="#FFFFFF"
                    style="margin-top: 100px">
                    <el-submenu index="1">
                        <template slot="title">
                            <i class="el-icon-user-solid" style="color: #FFFFFF"></i><span>信息管理</span>
                        </template>
                        <el-menu-item-group>
                            <el-menu-item index="/admin" v-if="user.role==='boss'" style="background-color: #04192c">管理员信息</el-menu-item>
                        </el-menu-item-group>
                        <el-menu-item-group>
                            <el-menu-item index="/user" >用户信息</el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>
                    <el-submenu index="2">
                        <template slot="title">
                            <i class="el-icon-user-solid" style="color: #FFFFFF"></i><span>脚本管理</span>
                        </template>
                        <el-menu-item-group>
                            <el-menu-item index="/script"style="background-color: #04192c">脚本</el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>
                </el-menu>
                <div style="color: #FFFFFF;position: absolute;margin-left: 30px;bottom: 100px;cursor: pointer;font-size: large" @click="update">升级日志</div>
                <el-popover
                        placement="right-start"
                        width="200"
                        trigger="hover"
                       >
                    <div>PC端地址：</div>
                    <div>http://localhost:8080/</div>
                    <div style="margin-top: 20px">想了解更多，请扫下面二维码
                        <el-image
                                style="width: 200px; height: 200px"
                                :src="'http://localhost:8081/api/files/erweima'"
                                :preview-src-list="['http://localhost:8081/api/files/erweima']"
                                :fit="fit">
                        </el-image>
                    </div>
                    <el-button slot="reference" style="background-color: #04192c;border: none;color: #FFFFFF;position: absolute;bottom: 20px;
                    font-size: large;margin-left: 10px">关于我们</el-button>
                </el-popover>
            </el-aside>
            <el-main style="background-color: #f0f2f5">
                <div style="position: absolute;width: 83%;height: 60px;background-color: #FFFFFF;top: 20px;margin-left: 10px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);border-radius: 0 0 20px 20px;top: 8px">
                    <div style="margin: 5px 20px 20px 20px;float: left;position: absolute;right: 100px;top: 0px">
                        <div class="block"><el-avatar :size="50" :src="net_url+user.img"></el-avatar></div>
                    </div>
                    <div style="margin-top: 5px;position: absolute;right: 70px;color: #3b3b3b"><span >{{user.name}}</span></div>
<!--                    <div style="margin-top: 10px;position: absolute;right: 30px;color: #3b3b3b;font-size: x-small;cursor: pointer" @click="logout"><span >[退出]</span></div>-->
                    <el-dropdown style="margin-top: 10px;position: absolute;right: 30px;color: #3b3b3b;cursor: pointer">
                        <span class="el-dropdown-link" style="color: black; font-size: x-small;"><div>[设置]</div></span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item ><div @click="edit_information">修改信息</div></el-dropdown-item>
                            <el-dropdown-item ><div @click="logout()">退出登录</div></el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <div style="margin-top: 30px;position: absolute;right: 30px;color: #777676"><span>{{currentTime}}</span></div>
                    <div  v-loading="loading">
                        <el-dialog
                                title="修改信息"
                                :visible.sync="dialogVisible"
                                width="30%"
                                :before-close="handleClose">
                            <el-form >
                                <el-form-item label="密码" label-width="20%">
                                    <el-input v-model="form.password" autocomplete="off" style="width:90%"></el-input>
                                </el-form-item>
                                <el-form-item label="头像" label-width="20%">
                                    <el-upload
                                            class="avatar-uploader"
                                            name="img"
                                            :action="this.load_url"
                                            :on-success="handleAvatarSuccess"
                                            :show-file-list="false"
                                            :before-upload="beforeAvatarUpload">
                                        <img v-if="imageUrl" :src="imageUrl" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </el-upload>
                                </el-form-item>
                            </el-form>
                            <span slot="footer" class="dialog-footer">
                        <el-button @click="dialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="submit">确 定</el-button>
                      </span>
                        </el-dialog>
                    </div>
                </div>
                <router-view/>
<!--                <div style="background-color: #3d3d3d;width:300px;height: 300px;display: ;">laos</div>-->
            </el-main>
        </el-container>

    </div>
</template>

<script>
import LoginView from "@/views/LoginView.vue";
import request from "@/utils/request";

export default {
    name: "Layout.vue",
    data(){
        return{
            user:localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) :{},
            currentTime: new Date().toLocaleTimeString(),
            beforeUnload: '',
            Handler: '',
            ceshi:{},
            form:{},
            net_url:LoginView.data().net_url,
            dialogVisible:false,
            load_url:LoginView.data().load_url,
            imageUrl:'',
            loading:false,

        }
    },
    mounted() {
        window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
        window.addEventListener('unload', e => this.unloadHandler(e))
    },
    destroyed() {
        window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
        window.removeEventListener('unload', e => this.unloadHandler(e))
    },
    created() {
        // 开始定时器
        setInterval(this.refreshData, 1000);
    },
    methods:{
        edit_information(){
            this.dialogVisible=true;
            this.imageUrl=this.net_url+this.user.img
        },
        source_material(){
            if(this.$route.path!=="/source"){
                this.$router.push("/source")
            }
        },
        editing_user(){
            if(this.$route.path!=="/user"){
                this.$router.push("/user")
            }
        },
        admin(){
            if(this.$route.path!=="/admin"){
                this.$router.push("/admin")
            }
        },
        update(){
            this.$router.push("/update")
        },
        refreshData() {
            this.currentTime = new Date().toLocaleTimeString();
        },
        beforeunloadHandler(){
            this.beforeUnload=new Date().getTime();
        },
        unloadHandler(e){
            this.Handler=new Date().getTime()-this.beforeUnload;
            //判断是窗口关闭还是刷新
            if(this.Handler<=5){
                this.logout();
            }
        },
        logout(){
            localStorage.removeItem("user");
            this.$router.push("/login");
        },
        search(){
            this.$message({
                message: 'success',
                type: 'success'
            });
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
        },
        handleAvatarSuccess(res) {
            this.form.img=res.img_address
            this.imageUrl=this.net_url+this.form.img
        },
        beforeAvatarUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isLt2M;
        },
        submit(){
            this.user.password=this.form.password;
            this.user.img=this.form.img;
            request.post("/admin",this.user).then(res=>{
                if(res.code==='0'){
                    this.$message.success("修改成功,请退出登录刷新信息");
                    this.dialogVisible = false
                }
            });
        },
    }
}
</script>

<style >
el-menu{
    border-right: none !important;
}
.no-border .el-input__inner {
    border: none !important;
    box-shadow: none !important;
}
</style>